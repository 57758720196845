/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const React = require("react")

// You can delete this file if you're not using it
// import 'bootstrap/dist/css/bootstrap.min.css';
const Bootstrap = require('bootstrap/dist/css/bootstrap.min.css')

// TODO: Need to see if this can help us.
exports.onRouteUpdate = ({ location, prevLocation }) => {
    // console.log("new pathname", location.pathname)
    // console.log("old pathname", prevLocation ? prevLocation.pathname : null)
    // This looks to solve my problem of loading the when the pages are hydrated.
    // We can just call it from here, and we should be
    // Stubs out the chdr info.
    // if (typeof m === "function") {
    //     // console.log("ldd: " + ldd)
    //     m();
    // }
    //
    // if (typeof m === "undefined") {
    //     function m() {
    //         return;
    //     }
    // }

    // try {
    //     m();
    // } catch (err) {
    //     console.log(err)
    // }
}

